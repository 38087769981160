import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'

import './index.scss'
import './password.scss'

import Layout from '../components/Layout'
import Button from '../components/Button'
import { isBrowser } from '../components/Auth'
import { changePassword } from '../services/api/users'
import { Context } from '../Context'

export default function PasswordPage () {
  const { isAuth } = useContext(Context)

  if (isBrowser() && !isAuth) {
    if (isBrowser()) {
      window.location.href = '/'
    }

    return (
      <Layout withoutHeader className='page-users'>
        Cargando...
      </Layout>
    )
  }

  const changePasswordFormSchema = Yup.object().shape({
    password: Yup.string().required('* Ingresa tu contraseña actual'),
    new_password: Yup.string()
      .required('* Ingresa tu nueva contraseña')
      .notOneOf([Yup.ref('password'), null], 'La nueva contraseña no puede ser igual a la anterior')
      .matches(/^.{8,}$/, 'Debe contener por lo menos 8 caracteres')
      .matches(/[A-Z]/, 'Debe contener por lo menos una letra mayúscula')
      .matches(/[a-z]/, 'Debe contener por lo menos una letra minúscula')
      .matches(/\d/, 'Debe contener por lo menos un número')
      .matches(/\W/, 'Debe contener por lo menos un caracter especial'),
    confirmation: Yup.string()
      .required('* Ingresa tu nueva contraseña de nuevo')
      .oneOf([Yup.ref('new_password'), null], 'Las constraseñas deben coincidir')
  })

  function onSubmit (values) {
    changePassword(values.password, values.new_password, values.confirmation)
      .then(() => {
        toast.success('Se cambió la contraseña correctamente')
        navigate('/profile/')
      })
      .catch(err => {
        console.dir(err)
        if (err.response) {
          toast.error(err.response.data.message)
        } else {
          toast.error('No fue posible guardar los cambios')
        }
      })
  }

  return (
    <Layout className='page-password'>
      <div
        style={{
          maxWidth: '600px',
          margin: '3rem auto',
          color: 'white'
        }}
      >
        <h1 style={{ color: 'white' }}>Cambiar contraseña</h1>

        <Formik
          onSubmit={onSubmit}
          initialValues={{ password: '', new_password: '', confirmation: '' }}
          validationSchema={changePasswordFormSchema}
          render={() => (
            <Form>
              <label>
                Contraseña actual
                <Field
                  type='password'
                  name='password'
                  placeholder='Contraseña actual'
                  className='textfield'
                  autoComplete='off'
                />
                <ErrorMessage name='password' component='small' className='text-danger' />
              </label>

              <label>
                Nueva contraseña
                <Field
                  type='password'
                  name='new_password'
                  placeholder='Nueva contraseña'
                  className='textfield'
                  autoComplete='off'
                />
                <ErrorMessage name='new_password' component='small' className='text-danger' />
              </label>

              <label>
                Confirmar contraseña
                <Field
                  type='password'
                  name='confirmation'
                  placeholder='Confirmar contraseña'
                  className='textfield'
                  autoComplete='off'
                />
                <ErrorMessage name='confirmation' component='small' className='text-danger' />
              </label>

              <div className='text-center'>
                <Button className='btn' type='submit'>
                  Guardar
                </Button>
              </div>
            </Form>
          )}
        />
      </div>
    </Layout>
  )
}
